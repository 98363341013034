import React from "react"
export default function Example() {
    return (
      <div className="relative py-16 bg-gray-50 overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-2xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              POLITIQUE DE CONFIDENTIALITÉ
              </span>
            </h1>
          </div>
          <div className="mt-6 prose prose-orange prose-lg text-gray-500 mx-auto text-justify">
            <p>
            En naviguant sur notre site, vous êtes informés que des cookies peuvent être installés sur votre équipement terminal.
            </p>
            <h2>
            Définition
            </h2>
            <p>
            Un cookie est un petit fichier, généralement constitué de lettres et de chiffres, stockée dans l’équipement terminal d’un internaute et utilisé par le site pour envoyer des informations au navigateur de l’internaute, et permettant à ce navigateur de renvoyer des informations au site d’origine (par exemple un identifiant de session, le choix d’une langue ou une date).
            </p>
            <p>
            Les cookies permettent de conserver, pendant la durée de validité du cookie concerné, des informations d’état lorsqu’un navigateur accède aux différentes pages d’un site web ou lorsque ce navigateur retourne ultérieurement sur ce site web.
            </p>
            <p>
            Les cookies sont stockés dans la mémoire de votre navigateur et chacun d’entre eux contient généralement :
            </p>
            <ul>
                <li>
                Le nom du serveur à partir duquel le cookie a été envoyé ;
                </li>
                <li>
                La durée de vie du cookie ;
                </li>
                <li>
                Une valeur – habituellement un nombre unique généré de manière aléatoire.
                </li>
                <li>
                Le serveur Internet qui envoie le cookie utilise ce numéro pour vous reconnaître quand vous retournez sur le site ou que vous naviguez de page en page. Seul l’émetteur d’un cookie peut lire ou modifier les informations qui y sont contenues.
                </li>
            </ul>
            <p>
            Il existe deux types de cookies, dits « provisoires » et « persistants » :
            </p>
            <ul>
                <li>
                Les cookies dits « provisoires » permettent aux sites Internet de faire le lien entre vos actions au cours d’une session de navigation. Ces cookies dits « provisoires » disparaissent à la fin de votre session de navigation et ne sont donc pas stockés pour une durée plus longue.
                </li>
                <li>
                Les cookies dits « persistants » sont stockés sur votre ordinateur entre les sessions de navigation et permettent de conserver en mémoire vos préférences ou actions sur un site (ou dans certains cas sur différents sites Internet). Les cookies dits « persistants » peuvent être utilisés pour des finalités diverses et notamment afin de se souvenir de vos préférences et choix lors de l’utilisation d’un site.
                </li>
            </ul>
            <p>
            Les cookies peuvent également être classés selon les catégories suivantes, les « cookies internes », qui proviennent du site Internet sur lequel vous naviguez, ou les « cookies tiers » qui proviennent d’un site Internet distinct de celui sur lequel vous naviguez.
            </p>
            <h2>
            Cookies utilisés sur les sites
            </h2>
            <p>
            CEOS Avocats utilise des cookies et technologies similaires comme les GIF à pixel unique et des pixels espions. Nous utilisons les cookies de session et les cookies persistants. 
            </p>
            <p>
            Nous utilisons principalement les cookies tiers, comme Google Analytics, à des fins d’analyse de fréquentation et pour détecter d’éventuel problème ou erreur technique sur le site  :
            </p>
            <p>
            Ci-après, l’application, le nom du cookie et la finalité du cookie.
            </p>
            <ul>
                <li>
                Google Analytics
(logiciel de suivi des visites)

                </li>
                <li>
                Google Search Console 
(logiciel de suivi des visites)

                </li>
                <li>
                __utma Ce cookie est utilisé pour distinguer les visiteurs uniques sur le site. Ce dernier est mis à jour à chaque page vue.
                </li>
                <li>
                __utmb Ce cookie est utilisé pour suivre la session de visite de l’internaute. Ce cookie expire dès que l’internaute reste inactif sur le site plus de 30 minutes. L’utilisation de ce cookie couplée avec le cookie utmc permet de suivre les visites (sessions) sur un site donnée.
                </li>
                <li>
                __utmc Ce cookie fonctionne en complément du cookie __utmb pour déterminer si oui ou non il y a une nouvelle visite par le visiteur unique actuel.
                </li>
                <li>
                __utmz Ce cookie stocke toutes les informations utiles à l’identification d’une source de trafic. C’est dans ce cookie que sont stockées les informations suivantes : la source de trafic, le support de cette source de trafic, le mot clé tapé si l’internaute consulte le site en provenance d’un moteur de recherche, etc.
                </li>
                <li>
                __utmv Segmentation des visiteurs.
 
                </li>
                <li>
                _ga Ce cookie stocke un identifiant permettant de distinguer dans Google Analytics les visiteurs.
                </li>
            </ul>
            <p>
            Il permet de ne compter qu’une fois un internaute qui viendrait sur le site plusieurs fois sur une même période de temps.

            </p>
            <h2>
            Gestion des cookies
            </h2>
            <p>
            Vous pouvez contrôler les cookies grâce aux paramètres de votre navigateur. Si la plupart des navigateurs sont paramétrés par défaut et acceptent l’installation de cookies, vous avez la possibilité, si vous le souhaitez, de choisir d’accepter tous les cookies, ou de les rejeter systématiquement ou encore de choisir ceux que vous acceptez selon l’émetteur. Vous pouvez également paramétrer votre navigateur pour accepter ou refuser au cas par cas les cookies préalablement à leur installation. Vous pouvez également régulièrement supprimer les cookies de votre terminal via votre navigateur.
            </p>
            <p>
            L’utilisation de cookies ou de technologies similaires par tous sites Internet tiers fournisseurs de contenus publicitaires est soumise à leur propre politique de confidentialité en matière de cookies.
            </p>
            <p>
            LA CNIL (Commission Nationale Informatique et Liberté) propose en téléchargement gratuit un logiciel de gestion des cookies sur son site Internet : rendez-vous <a href="https://www.cnil.fr/vos-droits/vos-traces/les-cookies/">sur le site de la CNIL</a> pour en savoir plus.
            </p>
            <p>
            Pour la gestion des cookies et de vos choix, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos souhaits en matière de cookies.

            </p>
            <p>
            A titre d’exemple :
            </p>
            <ul>
                <li>
                pour Internet Explorer™ : https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                </li>
                <li>
                pour Safari™ : https://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html
                </li>
                <li>
                pour Chrome™: https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647
                </li>
                <li>
                pour Firefox™ : https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies
                </li>
                <li>
                pour Opera™ : Web preferences - Opera Help
                </li>
            </ul>
            <p>
            Toutefois, nous vous informons qu’en paramétrant votre navigateur pour refuser les cookies, certaines fonctionnalités, pages, espaces du site ne seront pas accessibles, ce dont l’éditeur du site ne saurait être responsable.
            </p>
          </div>
        </div>
      </div>
    )
  }
  