import React from "react"
import { Helmet } from "react-helmet"
import KdpHeader from "../../blocs/Header/Header"
import KdpFooter from "../../blocs/Footer/Footer"
import KdpAriane from "../../blocs/Ariane"
import KdpPolitiqueConfidentialite from "../../blocs/PolitiqueConfidentialite"

const ariane = [
  { name: 'Politique de confidentialité', href: '', current: true },
]

export default function Cgu() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Politique en matière de cookies | Aspects juridiques | CEOS Avocats</title>
        <meta name="description" content="Conditions d‘utilisation de CEOS Avocats" />
      </Helmet>
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpAriane 
          filariane={ariane}
        />
        


        <KdpPolitiqueConfidentialite />




      </main>
      <footer>
      <KdpFooter />
      </footer>
    </div>
  );
}